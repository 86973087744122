import { FrontendLocaleData } from "../../data/translation";
import { formatList } from "../list/format_list";
import { LocalizeFunc } from "../translations/localize";

export function getMainError(localize: LocalizeFunc, err?: any) {
  return (
    getTranslationKeyError(localize, err?.error) +
    (err?.body?.message ? `: ${err.body.message}` : "")
  );
}

export function getFullError(
  localize: LocalizeFunc,
  locale: FrontendLocaleData,
  errObject?: any
) {
  const errorMessage: string[] = [];

  if (errObject?.error)
    errorMessage.push(getTranslationKeyError(localize, errObject.error));

  if (errObject.body) {
    const messages = manageError(errObject.body);

    if (messages && messages.length > 0) {
      messages.forEach((message) =>
        errorMessage.push(getTranslationKeyError(localize, message))
      );
    }
  }

  return errorMessage && errorMessage.length > 0
    ? formatList(locale, errorMessage)
    : localize("ui.common.unknown_error");
}

export function getTranslationKeyError(localize: LocalizeFunc, message?: any) {
  let messageTranslated;

  if (
    message &&
    typeof message === "string" &&
    message.startsWith("ui.") &&
    message.includes(",")
  ) {
    const [translationKey, variableKey, variable] = message.split(",");
    messageTranslated = localize(translationKey, variableKey, variable);
  } else {
    messageTranslated = localize(message);
  }

  if (
    messageTranslated &&
    messageTranslated.indexOf("Translation Error") === -1
  )
    return messageTranslated;
  return message || "";
}

export function manageError(data: any) {
  const messages: any = [];

  if (data) {
    if (
      Object.prototype.hasOwnProperty.call(data, "error") &&
      Object.prototype.hasOwnProperty.call(data.error, "details")
    ) {
      data.details = data.error.details;
    }

    if (
      Object.prototype.hasOwnProperty.call(data, "details") &&
      typeof data.details === "object"
    ) {
      if (Object.prototype.hasOwnProperty.call(data, "message")) {
        messages.push(data.message);
      }

      Object.keys(data.details).forEach((key) => {
        const field = key.endsWith("Aql") ? key.slice(0, -3) : key;

        let message = field + " : " + data.details[key];

        if (message.substr(0, 3) === "is ") {
          message = field + " " + message;
        }

        messages.push(message);
      });
    } else if (
      Object.prototype.hasOwnProperty.call(data, "details") &&
      typeof data.details === "string"
    ) {
      messages.push(data.details);
    } else {
      messages.push(data.message);
    }
  }

  return messages;
}

export function renderErrorOnDelete(
  title: string,
  checkErrorMessage: {
    message: string;
    details: string[];
  }
) {
  return `${title}\n\n${
    checkErrorMessage?.message || ""
  }\n\n${checkErrorMessage?.details?.join("\n")}`;
}
