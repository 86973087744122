"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TauiScope = void 0;
var TauiScope;
(function (TauiScope) {
    // endUser
    TauiScope["ACCOUNT_VIEW"] = "account:view";
    TauiScope["ACCOUNT_MANAGE"] = "account:manage";
    TauiScope["CONTENT_BROWSE"] = "content:browse";
    TauiScope["CONTENT_CONSUME"] = "content:consume";
    // backendUser > dashboard
    TauiScope["DASHBOARD_VIEW"] = "dashboard:view";
    TauiScope["DASHBOARD_EDIT"] = "dashboard:edit";
    // backendUser > source
    TauiScope["SOURCE_CONTENT_VIEW"] = "source:content:view";
    TauiScope["SOURCE_CONTENT_EDIT"] = "source:content:edit";
    TauiScope["SOURCE_TENANT_VIEW"] = "source:tenant:view";
    TauiScope["SOURCE_TENANT_EDIT"] = "source:tenant:edit";
    TauiScope["SOURCE_TENANT_MANAGE"] = "source:tenant:manage";
    // backendUser > enrich
    TauiScope["ENRICH_VIEW"] = "enrich:view";
    TauiScope["ENRICH_EDIT"] = "enrich:edit";
    TauiScope["ENRICH_MANAGE"] = "enrich:manage";
    // backendUser > experience
    TauiScope["EXPERIENCE_CONTENT_VIEW"] = "experience:content:view";
    TauiScope["EXPERIENCE_CONTENT_EDIT"] = "experience:content:edit";
    TauiScope["EXPERIENCE_USER_VIEW"] = "experience:user:view";
    TauiScope["EXPERIENCE_USER_EDIT"] = "experience:user:edit";
    TauiScope["EXPERIENCE_USER_MANAGE"] = "experience:user:manage";
    TauiScope["EXPERIENCE_MONETIZATION_VIEW"] = "experience:monetization:view";
    TauiScope["EXPERIENCE_MONETIZATION_EDIT"] = "experience:monetization:edit";
    TauiScope["EXPERIENCE_MONETIZATION_MANAGE"] = "experience:monetization:manage";
    // backendUser > publish
    TauiScope["PUBLISH_CONTENT_VIEW"] = "publish:content:view";
    TauiScope["PUBLISH_CONTENT_EDIT"] = "publish:content:edit";
    TauiScope["PUBLISH_CONTENT_MANAGE"] = "publish:content:manage";
    TauiScope["PUBLISH_MANAGEMENT_VIEW"] = "publish:management:view";
    TauiScope["PUBLISH_MANAGEMENT_EDIT"] = "publish:management:edit";
    TauiScope["PUBLISH_MANAGEMENT_MANAGE"] = "publish:management:manage";
})(TauiScope || (exports.TauiScope = TauiScope = {}));
