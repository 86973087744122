import {
  setPassiveTouchGestures,
  setCancelSyntheticClickEvents,
} from "@polymer/polymer/lib/utils/settings";
import "@webcomponents/scoped-custom-element-registry/scoped-custom-element-registry.min";
import "../layouts/taui-app";
import "../resources/taui-style";
import "../resources/roboto";

setPassiveTouchGestures(true);
setCancelSyntheticClickEvents(false);
