import { BackendService } from "@taui/shared/types/backend-service";
import {
  AssemblingParams,
  MediaDTO,
} from "@taui/shared/interfaces/openapi/studio-api";
import { TucanoAdminUI } from "../../types";

export const studioAssembleChunks = async (
  taui: TucanoAdminUI,
  mediaId: string,
  params: AssemblingParams
) =>
  taui.callApi<void>(
    "POST",
    BackendService.studio,
    `/media/${mediaId}/upload/assemble`,
    params
  );

export const studioResetUpload = async (taui: TucanoAdminUI, mediaId: string) =>
  taui.callApi<MediaDTO>(
    "PUT",
    BackendService.studio,
    `/media/${mediaId}/upload/reset`
  );
