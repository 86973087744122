import { PropertyValues, ReactiveElement } from "lit";
import { property } from "lit/decorators";
import memoizeOne from "memoize-one";
import { navigate } from "../common/navigate";
import { BreadcrumbPath, Route, TabInfo, TucanoAdminUI } from "../types";
import { fireEvent } from "../common/dom/fire_event";
import { canShowPage } from "../common/config/can_show_page";

const DEBUG = false;

const extractPage = (path: string, defaultPage: string) => {
  if (path === "") {
    return defaultPage;
  }
  const subpathStart = path.indexOf("/", 1);
  return subpathStart === -1
    ? path.substr(1)
    : path.substr(1, subpathStart - 1);
};

export interface RouteOptions {
  // HTML tag of the route page.
  tag: string;
  // Function to load the page.
  load?: () => Promise<unknown>;
  cache?: boolean;
  isMaster?: boolean;
}

export interface RouterOptions {
  // The default route to show if path does not define a page.
  defaultPage?: string;
  // If all routes should be preloaded
  preloadAll?: boolean;
  // If a route has been shown, should we keep the element in memory
  cacheAll?: boolean;
  cacheName?: (page) => string | undefined;
  // Should we show a loading spinner while we load the element for the route
  showLoading?: boolean;
  // Promise that resolves when the initial data is loaded which is needed to show any route.
  initialLoad?: () => Promise<unknown>;
  // Hook that is called before rendering a new route. Allowing redirects.
  // If string returned, that page will be rendered instead.
  beforeRender?: (page: string) => string | undefined;
  routes: {
    // If it's a string, it is another route whose options should be adopted.
    [route: string]: RouteOptions | string;
  };
}

// Time to wait for code to load before we show loading screen.
const LOADING_SCREEN_THRESHOLD = 400; // ms

export class TauiRouterPage extends ReactiveElement {
  @property({ attribute: false }) public taui?: TucanoAdminUI;

  @property() public route?: Route;

  @property() public cache?;

  @property() public breadcrumbPath?: BreadcrumbPath[];

  protected routerOptions!: RouterOptions;

  protected _currentPage = "";

  private _currentLoadProm?: Promise<void>;

  private _initialLoadDone = false;

  private _computeTail = memoizeOne((route: Route) => {
    const dividerPos = route.path.indexOf("/", 1);
    return dividerPos === -1
      ? {
          prefix: route.prefix + route.path,
          path: "",
        }
      : {
          prefix: route.prefix + route.path.substr(0, dividerPos),
          path: route.path.substr(dividerPos),
        };
  });

  private _computeBreadcrumbPath = memoizeOne(
    (
      route: Route,
      breadcrumbPath?: BreadcrumbPath[],
      clickable = false,
      add?: BreadcrumbPath
    ) => {
      let newBreadcrumbPath: BreadcrumbPath[] = [...(breadcrumbPath || [])];

      const lastPrefixKey = route?.prefix?.split("/")?.pop();

      if (lastPrefixKey) {
        const lastPath = [...newBreadcrumbPath].pop();

        if (!lastPath || lastPath?.key !== lastPrefixKey)
          newBreadcrumbPath = [
            ...newBreadcrumbPath,
            {
              key: lastPrefixKey,
              clickable,
            },
          ];
      }

      const dividerPos = route.path.indexOf("/", 1);

      if (dividerPos === -1 && route.path && route.path !== "/list") {
        newBreadcrumbPath = [
          ...newBreadcrumbPath,
          {
            key: route.path.substring(1),
            clickable,
          },
        ];
      }

      if (add) {
        newBreadcrumbPath = [...newBreadcrumbPath, { ...add, clickable }];
      }

      return newBreadcrumbPath;
    }
  );

  protected createRenderRoot() {
    return this;
  }

  protected update(changedProps: PropertyValues) {
    super.update(changedProps);

    const routerOptions = this.routerOptions || { routes: {} };

    if (routerOptions && routerOptions.initialLoad && !this._initialLoadDone) {
      return;
    }

    if (!changedProps.has("route")) {
      // Do not update if we have a currentLoadProm, because that means
      // that there is still an old panel shown and we're moving to a new one.
      if (this.lastChild && !this._currentLoadProm) {
        this.updatePageEl(this.lastChild, changedProps);
      }
      return;
    }

    const route = this.route;
    const defaultPage = routerOptions.defaultPage;

    if (route && route.path === "" && defaultPage !== undefined) {
      navigate(`${route.prefix}/${defaultPage}${location.search}`, {
        replace: true,
      });
    }

    if (route) this.storeRoute({ ...route, search: location.search });

    let newPage = route
      ? extractPage(route.path, defaultPage || "")
      : "not_found";
    let routeOptions = routerOptions.routes[newPage];

    // Handle redirects
    while (typeof routeOptions === "string") {
      newPage = routeOptions;
      routeOptions = routerOptions.routes[newPage];
    }

    if (routerOptions.beforeRender) {
      const result = routerOptions.beforeRender(newPage);
      if (result !== undefined) {
        newPage = result;
        routeOptions = routerOptions.routes[newPage];

        // Handle redirects
        while (typeof routeOptions === "string") {
          newPage = routeOptions;
          routeOptions = routerOptions.routes[newPage];
        }

        // Update the url if we know where we're mounted.
        if (route) {
          navigate(`${route.prefix}/${result}${location.search}`, {
            replace: true,
          });
        }
      }
    }

    if (this._currentPage === newPage) {
      let moduleName;

      if (this.lastChild) {
        // eslint-disable-next-line prefer-const
        let { panelEl, cacheName } = this._checkCache(
          routerOptions,
          newPage,
          routeOptions.cache
        );
        moduleName = cacheName;

        if (
          "itemId" in this.lastChild &&
          this.lastChild?.itemId === panelEl?.itemId
        )
          moduleName = undefined;

        if (!panelEl) panelEl = this.lastChild;

        if (!moduleName) this.updatePageEl(panelEl, changedProps);
      }

      if (!moduleName) return;
    }

    if (!routeOptions) {
      this._currentPage = "";
      if (this.lastChild) {
        this.removeChild(this.lastChild);
      }
      return;
    }

    this._currentPage = newPage;
    const loadProm = routeOptions.load
      ? routeOptions.load()
      : Promise.resolve();

    let showLoadingScreenTimeout: undefined | number;

    // Check when loading the page source failed.
    loadProm.catch((err) => {
      // eslint-disable-next-line
      console.error("Error loading page", newPage, err);

      // Verify that we're still trying to show the same page.
      if (this._currentPage !== newPage) {
        return;
      }

      // Removes either loading screen or the panel
      if (this.lastChild) {
        this.removeChild(this.lastChild!);
      }

      if (showLoadingScreenTimeout) {
        clearTimeout(showLoadingScreenTimeout);
      }

      // Show error screen
      this.appendChild(
        this.createErrorScreen(`Error while loading page ${newPage}.`)
      );
    });

    // If we don't show loading screen, just show the panel.
    // It will be automatically upgraded when loading done.
    if (!routerOptions.showLoading) {
      this._createPanel(routerOptions, newPage, routeOptions, route);
      return;
    }

    // We are only going to show the loading screen after some time.
    // That way we won't have a double fast flash on fast connections.
    let created = false;

    showLoadingScreenTimeout = window.setTimeout(() => {
      if (created || this._currentPage !== newPage) {
        return;
      }

      // Show a loading screen.
      if (this.lastChild) {
        this.removeChild(this.lastChild);
      }
      this.appendChild(this.createLoadingScreen());
    }, LOADING_SCREEN_THRESHOLD);

    this._currentLoadProm = loadProm.then(
      () => {
        this._currentLoadProm = undefined;
        // Check if we're still trying to show the same page.
        if (this._currentPage !== newPage) {
          return;
        }

        created = true;

        this._createPanel(
          routerOptions,
          newPage,
          // @ts-ignore TS forgot this is not a string.
          routeOptions,
          route
        );
      },
      () => {
        this._currentLoadProm = undefined;
      }
    );
  }

  protected firstUpdated(changedProps: PropertyValues) {
    super.firstUpdated(changedProps);

    const options = this.routerOptions;

    if (!options) {
      return;
    }

    if (options.preloadAll) {
      Object.values(options.routes).forEach(
        (route) => typeof route === "object" && route.load && route.load()
      );
    }

    if (options.initialLoad) {
      setTimeout(() => {
        if (!this._initialLoadDone) {
          this.appendChild(this.createLoadingScreen());
        }
      }, LOADING_SCREEN_THRESHOLD);

      options.initialLoad().then(() => {
        this._initialLoadDone = true;
        this.requestUpdate("route");
      });
    }
  }

  protected createLoadingScreen() {
    import("./taui-loading-screen");
    return document.createElement("taui-loading-screen");
  }

  protected createErrorScreen(error: string) {
    import("./taui-error-screen");
    const errorEl = document.createElement("taui-error-screen");
    errorEl.error = error;
    return errorEl;
  }

  /**
   * Rebuild the current panel.
   *
   * Promise will resolve when rebuilding is done and DOM updated.
   */
  protected async rebuild(): Promise<void> {
    const oldRoute = this.route;

    if (oldRoute === undefined) {
      return;
    }

    this.route = undefined;
    await this.updateComplete;
    // Make sure that the parent didn't override this in the meanwhile.
    if (this.route === undefined) {
      this.route = oldRoute;
    }
  }

  /**
   * Promise that resolves when the page has rendered.
   */
  protected get pageRendered(): Promise<void> {
    return this.updateComplete.then(() => this._currentLoadProm);
  }

  protected createElement(tag: string) {
    return document.createElement(tag);
  }

  protected updatePageEl(_pageEl, _changedProps?: PropertyValues) {
    // default we do nothing
  }

  protected storeRoute(_route: Route) {
    // default we do nothing
  }

  protected hasAccess(url_path, isMaster) {
    if (!this.taui) return true;

    if (!isMaster && !canShowPage(this.taui, url_path)) return false;

    if (isMaster && !this.taui.tenantMaster) return false;

    return true;
  }

  protected storeTab(tabInfo: TabInfo) {
    if (tabInfo?.base_url_path) {
      fireEvent(this as any, "taui-tabs-add-tab", tabInfo);
    }
  }

  protected get routeTail(): Route {
    return this._computeTail(this.route!);
  }

  protected breadcrumbPathTail(
    clickage?: boolean,
    item?: BreadcrumbPath
  ): BreadcrumbPath[] {
    return this._computeBreadcrumbPath(
      this.route!,
      this.breadcrumbPath,
      clickage,
      item
    );
  }

  private _createPanel(
    routerOptions: RouterOptions,
    page: string,
    routeOptions: RouteOptions,
    route?: Route
  ) {
    if (this.lastChild) {
      this.removeChild(this.lastChild);
    }

    const prefixRoute = route?.prefix?.replace(/^\/|\/$/g, "");
    if (
      !this.hasAccess(
        prefixRoute ? `${prefixRoute}/${page}` : page,
        routeOptions.isMaster
      )
    ) {
      // Show error screen
      this.appendChild(
        this.createErrorScreen(
          this.taui?.localize("ui.common.not_authorized") ||
            "Unable to access this page"
        )
      );
      return;
    }

    // eslint-disable-next-line prefer-const
    let { panelEl, cacheName } = this._checkCache(
      routerOptions,
      page,
      routeOptions.cache
    );

    if (!panelEl) panelEl = this.createElement(routeOptions.tag);

    this.updatePageEl(panelEl);
    this.appendChild(panelEl);

    if (routerOptions.cacheAll || routeOptions.cache) {
      // eslint-disable-next-line no-console
      if (DEBUG) console.log("Store panel in cache", cacheName);
      this.cache[cacheName] = panelEl;
    }
  }

  private _checkCache(
    routerOptions: RouterOptions,
    page: string,
    cache?: boolean
  ) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.log(`checkCache - Caches`, this.cache);
    }
    let cacheName;
    let panelEl;

    if (routerOptions.cacheAll || cache) {
      if (routerOptions.cacheName) {
        cacheName = routerOptions.cacheName(page);
      }

      if (cacheName) {
        panelEl = this.cache[cacheName];

        if (DEBUG) {
          if (panelEl) {
            // eslint-disable-next-line no-console
            console.log(`checkCache - Cache ${cacheName} found`, panelEl);
          } else {
            // eslint-disable-next-line no-console
            console.error(`checkCache - Cache ${cacheName} not found`);
          }
        }
      } else {
        // eslint-disable-next-line no-console, no-lonely-if
        if (DEBUG) console.error("checkCache - No cache name");
      }
    }

    return {
      panelEl,
      cacheName,
    };
  }
}
