import { Auth } from "../data/auth";

export const fetchWithAuth = async (
  auth: Auth,
  input: RequestInfo,
  init: RequestInit = {}
) => {
  if (auth.expired) {
    await auth.refreshAccessToken().catch(async (_err) => {
      await auth.revoke();

      location.reload();
    });
  }

  init.credentials = "same-origin";

  if (!init.headers) {
    init.headers = {};
  }

  // use cookie authentication
  init.credentials = "include";

  return fetch(input, init);
};
