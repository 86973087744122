import { TAUIDomEvent } from "../common/dom/fire_event";
import { Constructor } from "../types";
import { TauiBaseEl } from "./taui-base-mixin";

interface WriteLogParams {
  level?: "debug" | "info" | "warning" | "error" | "critical";
  message: string;
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    write_log: WriteLogParams;
  }
  interface HTMLElementEventMap {
    write_log: TAUIDomEvent<WriteLogParams>;
  }
}

export const loggingMixin = <T extends Constructor<TauiBaseEl>>(
  superClass: T
) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("write_log", (ev) => {
        this._writeLog(ev.detail);
      });
    }

    private _writeLog(log: WriteLogParams) {
      const detail = {
        logger: `frontend.${
          __DEV__ ? "js_dev" : "js"
        }.${__BUILD__}.${__VERSION__.replace(".", "")}`,
        message: log.message,
        level: log.level || "error",
      };

      switch (log.level) {
        case "info":
          // eslint-disable-next-line no-console
          console.info("INFO", detail);
          break;
        case "warning":
          // eslint-disable-next-line no-console
          console.warn("WARNING", detail);
          break;
        case "error":
          // eslint-disable-next-line no-console
          console.error("ERROR", detail);
          break;
        case "critical":
          // eslint-disable-next-line no-console
          console.error("CRITICAL", detail);
          break;
        default:
          break;
      }
    }
  };
