export function removeRecursiveByService(obj, ids) {
  let index;

  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (prop === "services") {
        // fetch intersection between both arrays
        const idsToCheck = ids.filter((v) => obj[prop].includes(v));
        index = obj[prop].every((v) => idsToCheck.includes(v));
        if (index) {
          Object.keys(obj).forEach((key) => {
            delete obj[key];
          });
        }
      } else if (typeof obj[prop] === "object") {
        removeRecursiveByService(obj[prop], ids);
      }
    }
  }

  return removeEmptyObject(obj);
}

// source: https://www.programmersought.com/article/62646768283/
function removeEmptyObject(object) {
  // eslint-disable-next-line guard-for-in
  for (const i in object) {
    const value = object[i];
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        if (value.length === 0) {
          delete object[i];
          continue;
        }
      }
      removeEmptyObject(value);
      // Add as needed
      if (isEmpty(value)) {
        delete object[i];
      }
    } else if (value === "" || value === null || value === undefined) {
      delete object[i];
    }
  }
  return object;
}

// Is it empty
function isEmpty(object) {
  // eslint-disable-next-line guard-for-in,no-unreachable-loop
  for (const _name in object) {
    return false;
  }
  return true;
}
