import { BackendService } from "@taui/shared/types/backend-service";
import {
  MediaDTO,
  MediaDTOList,
  MediaParams,
} from "@taui/shared/interfaces/openapi/studio-api";
import { TucanoAdminUI } from "../../types";

export const studioListMedias = async (taui: TucanoAdminUI, assetId: string) =>
  taui.callApi<MediaDTOList>(
    "GET",
    BackendService.studio,
    `/asset/${assetId}/media`
  );

export const studioGetMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string
) =>
  taui.callApi<MediaDTO>(
    "GET",
    BackendService.studio,
    `/asset/${assetId}/media/${mediaId}`
  );

export const studioCreateMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "POST",
    BackendService.studio,
    `/asset/${assetId}/media`,
    params
  );

export const studioUpdateMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string,
  params: MediaParams
) =>
  taui.callApi<MediaDTO>(
    "PUT",
    BackendService.studio,
    `/asset/${assetId}/media/${mediaId}`,
    params
  );

export const studioDeleteMedia = async (
  taui: TucanoAdminUI,
  assetId: string,
  mediaId: string
) =>
  taui.callApi<void>(
    "DELETE",
    BackendService.studio,
    `/asset/${assetId}/media/${mediaId}`
  );
