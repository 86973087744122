"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackendService = void 0;
var BackendService;
(function (BackendService) {
    // Backend services
    BackendService["analytics"] = "analytics";
    BackendService["billing"] = "billing";
    BackendService["catchup"] = "catchup";
    BackendService["cms"] = "cms";
    BackendService["crm"] = "crm";
    BackendService["dashboard"] = "dashboard";
    BackendService["enrichment"] = "enrichment";
    BackendService["entitlements"] = "entitlements";
    BackendService["export"] = "export";
    BackendService["identity"] = "identity";
    BackendService["image"] = "image";
    BackendService["ipinfo"] = "ipinfo";
    BackendService["npvr"] = "npvr";
    BackendService["oidc"] = "oidc";
    BackendService["privacy"] = "privacy";
    BackendService["publication"] = "publication";
    BackendService["realtime"] = "realtime";
    BackendService["recommendation"] = "recommendation";
    BackendService["reporting"] = "reporting";
    BackendService["search"] = "search";
    BackendService["studio"] = "studio";
    BackendService["suboffer"] = "suboffer";
    BackendService["analyticsreporting"] = "analyticsreporting";
})(BackendService || (exports.BackendService = BackendService = {}));
