/* eslint-disable lit/prefer-static-styles */
import "@polymer/paper-tabs/paper-tab";
import "@lrnwebcomponents/simple-tooltip/simple-tooltip";
import {
  css,
  CSSResultGroup,
  html,
  LitElement,
  PropertyValues,
  TemplateResult,
} from "lit";
import { customElement, property, state } from "lit/decorators";
import { repeat } from "lit/directives/repeat";
import { ifDefined } from "lit/directives/if-defined";
import { storage } from "../common/decorators/storage";
import { fireEvent, TAUIDomEvent } from "../common/dom/fire_event";
import { listenMediaQuery } from "../common/dom/media_query";
import { toggleAttribute } from "../common/dom/toggle_attribute";
import { navigate } from "../common/navigate";
import { truncStringPortion } from "../common/string/trunc-middle-portion-text";
import { computeRTLDirection } from "../common/util/compute_rtl";
import "../components/taui-button-menu";
import "../components/taui-circular-progress";
import "../components/taui-drawer";
import "../components/taui-icon";
import "../components/taui-icon-button";
import "../components/taui-linear-progress";
import "../components/taui-list-item";
import "../components/taui-tabs";
import "../components/taui-top-app-bar-fixed";
import { showConfirmationDialog } from "../dialogs/generic/show-dialog-box";
import { tauiStyle } from "../resources/styles";
import type { Route, TucanoAdminUI } from "../types";
import { TabInfo } from "../types";
import { removeLaunchScreen } from "../util/launch-screen";
import "./partial-panel-resolver";
import { DEFAULT_TENANT } from "../util/taui-tenant";
import { setDefaultPanel } from "../data/panel";
import { setDefaultDashboard } from "../data/dashboard";

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-toggle-menu": undefined | { open?: boolean };
  }

  interface HTMLElementEventMap {
    "taui-toggle-menu": TAUIDomEvent<TAUIDomEvents["taui-toggle-menu"]>;
  }
}

@customElement("taui-app-main")
class TauiAppMain extends LitElement {
  @property({ attribute: false }) public taui!: TucanoAdminUI;

  @property() public route?: Route;

  @property() cache;

  @property({ type: Boolean, reflect: true }) public narrow!: boolean;

  @property({ type: Boolean, reflect: true }) public rtl = false;

  @state() private _drawerOpen = false;

  @storage({
    key: "selectedTenant",
    state: true,
    subscribe: true,
  })
  private _selectedTenant = DEFAULT_TENANT;

  @storage({
    key: "tenants",
    state: true,
    subscribe: true,
  })
  private _tenants: string[] = [];

  private _tabs = (tabs): TabInfo[] =>
    tabs?.filter((el) => el.url_path !== "dashboards") || [];

  constructor() {
    super();
    listenMediaQuery("(max-width: 870px)", (matches) => {
      this.narrow = matches;
    });
  }

  private get selectedPanel() {
    return this.taui.selectedPanel?.split("?")?.[0];
  }

  protected render(): TemplateResult {
    const sidebarNarrow = this._sidebarNarrow;

    const tabs = this._tabs(this.taui.tabs);

    // Style block in render because of the mixin that is not supported
    return html` <taui-drawer
      .type=${sidebarNarrow ? "modal" : ""}
      .open=${sidebarNarrow ? this._drawerOpen : undefined}
      .direction=${computeRTLDirection(this.taui)}
      @MDCDrawer:closed=${this._drawerClosed}
    >
      <taui-sidebar
        .taui=${this.taui}
        .narrow=${sidebarNarrow}
        .alwaysExpand=${sidebarNarrow || this.taui.dockedSidebar === "docked"}
        .tenants=${this._tenants}
        .selectedTenant=${this.taui.selectedTenant}
        .allTenants=${this.taui.allTenants}
        @tenant-changed=${this._tenantChanged}
      ></taui-sidebar>

      <div slot="appContent">
        <taui-top-app-bar-fixed>
          <taui-icon-button
            .label=${this.taui.localize("ui.components.sidebar.sidebar_toggle")}
            .icon=${"fad:bars"}
            @click=${this.toggleSidebar}
            slot="navigationIcon"
          >
          </taui-icon-button>

          <taui-tabs
            slot="title"
            scrollable
            attr-for-selected="url-path"
            .selected=${this.selectedPanel}
            @iron-activate=${this.handleTabSelected}
            dir=${computeRTLDirection(this.taui)}
          >
            ${repeat(
              tabs,
              (tab) => tab.url_path,
              (tab) => {
                const tabTranslated = tab?.name
                  ? tab.name
                  : this._getTranslation(this.taui.localize, tab.base_url_path);

                return html`<paper-tab
                  aria-label=${ifDefined(tabTranslated)}
                  url-path=${tab.url_path}
                  .tab=${tab}
                >
                  <div class="tab-content">
                    ${tab.error ? html`<div class="errorTab">!</div>` : ""}
                    ${tab.edited ? html` * ` : ""} ${tabTranslated}
                    <taui-icon
                      title="Close"
                      .icon=${"fad:xmark"}
                      .tab=${tab}
                      @click=${this._closeAt}
                    >
                    </taui-icon>
                  </div>
                </paper-tab>`;
              }
            )}
          </taui-tabs>

          <div slot="actionItems" class="actionItems">
            ${tabs?.length > 0
              ? html` <taui-button-menu fixed y="4">
                  <taui-icon-button
                    .label=${this.taui.localize("ui.common.menu")}
                    .icon=${"fad:ellipsis-vertical"}
                    slot="trigger"
                  >
                  </taui-icon-button>

                  <taui-list-item @click=${this._closeAllTabs}>
                    ${this.taui.localize("ui.components.tabs.close_all_tabs")}
                  </taui-list-item>
                  <taui-list-item @click=${this._closeOtherTabs}>
                    ${this.taui.localize("ui.components.tabs.close_other_tabs")}
                  </taui-list-item>
                </taui-button-menu>`
              : ""}
            ${this.taui.upload.current
              ? html` <div
                  class="uploading"
                  @click=${this._openCreatVodAssetList}
                >
                  <taui-circular-progress
                    alt="Uploading"
                    active
                  ></taui-circular-progress>
                  <div id="details">
                    <span
                      >${this._getCurrentUploadFilename(
                        this.taui.upload.current,
                        "true"
                      )}</span
                    >
                  </div>
                  <simple-tooltip position="left" for="details"
                    >${this._getCurrentUploadFilename(
                      this.taui.upload.current,
                      "false"
                    )}
                  </simple-tooltip>
                </div>`
              : ""}
            ${!sidebarNarrow
              ? html`
                  ${this.taui.locale?.time_zone !== "browser"
                    ? html` <div class="icon-button">
                        <taui-icon icon="fad:clock"></taui-icon>
                        <simple-tooltip position="left">
                          ${this.taui.locale?.time_zone}
                        </simple-tooltip>
                      </div>`
                    : ""}
                `
              : ""}
          </div>
        </taui-top-app-bar-fixed>

        <taui-linear-progress
          ?active=${this.taui.loading}
        ></taui-linear-progress>

        <partial-panel-resolver
          .narrow=${this.narrow}
          .taui=${this.taui}
          .route=${this.route}
          .cache=${this.cache}
        ></partial-panel-resolver>
      </div>
    </taui-drawer>`;
  }

  protected firstUpdated() {
    removeLaunchScreen();

    import(/* webpackPreload: true */ "../components/taui-sidebar");

    this.addEventListener("taui-toggle-menu", () => {
      this._drawerOpen = !this._drawerOpen;
    });

    this.addEventListener("taui-toggle-menu", () => {
      this._drawerOpen = !this._drawerOpen;
    });

    this.addEventListener("tenant-slug-name-changed", (ev) => {
      fireEvent(this, "selected-tenant-changed", ev.detail);

      this._selectedTenant = ev.detail;
    });

    this.addEventListener("update-tenants-slug-storage", (ev) => {
      fireEvent(this, "stored-tenants-changed", ev.detail);
      fireEvent(this as any, "taui-refresh-all-tenants");

      this._tenants = ev.detail;
    });

    this._launchStartup();
  }

  public willUpdate(changedProps: PropertyValues) {
    if (changedProps.has("route") && this._sidebarNarrow) {
      this._drawerOpen = false;
    }
  }

  protected updated(changedProps: PropertyValues) {
    super.updated(changedProps);

    toggleAttribute(this, "expanded", this.taui.dockedSidebar === "docked");

    toggleAttribute(this, "modal", this._sidebarNarrow);
  }

  _getTranslation(localize, name: string) {
    const keyTranslation = name?.split("/")?.filter((n) => n !== "");

    const nameTranslated = localize(`panel.${keyTranslation?.join(".")}.title`);

    if (nameTranslated) return nameTranslated;

    return (
      keyTranslation?.filter((n) => n !== "list" && n !== "edit")?.join(" ") ||
      name
    );
  }

  toggleSidebar() {
    if (this._sidebarNarrow) {
      this._drawerOpen = true;
    } else {
      fireEvent(this, "taui-dock-sidebar", {
        dock: this.taui.dockedSidebar === "auto" ? "docked" : "auto",
      });
    }
  }

  private async _launchStartup() {
    if (
      !this.taui.previousIdentity?.id_identity ||
      this.taui.auth.idIdentity !== this.taui.previousIdentity?.id_identity
    ) {
      fireEvent(this as any, "tabs-changed", []);
      fireEvent(this as any, "identity-changed", this.taui.auth.data);
    } else if (this.taui.config && this.taui.config?.featureFlag?.rememberTab) {
      const tabs = this.taui?.tabs?.filter((t) => t.base_url_path);

      fireEvent(
        this as any,
        "tabs-changed",
        tabs?.map((tab) => ({
          ...tab,
          edited: false,
          error: false,
        })) || []
      );
    }
  }

  private async _tenantChanged(ev) {
    const tenant = ev?.detail;
    const oldTenantSlug = tenant?.oldSlug;
    const newTenantSlug = tenant?.newSlug;
    const tenantAction = tenant?.action;
    const tabsUpdated = await this._hasTabUpdated(this.taui.tabs);

    if (!tabsUpdated) {
      fireEvent(this as any, "tabs-changed", []);
      fireEvent(this as any, "route-page-remove-all-cache");
      setDefaultPanel(this as any, "/");

      setDefaultDashboard(this, -1);

      this._selectedTenant =
        newTenantSlug || this._selectedTenant || DEFAULT_TENANT;

      fireEvent(this, "selected-tenant-changed", this._selectedTenant);

      if (tenantAction === "ADDED") {
        this._tenants = [
          ...new Set([...(this._tenants || []), this._selectedTenant]),
        ];
      } else if (tenantAction === "REMOVED") {
        this._tenants = [
          ...(this._tenants?.filter((t) => t !== oldTenantSlug) || []),
        ];
      }

      if (!this.taui.tenantMaster) {
        fireEvent(this, "taui-logout");
      } else {
        document.location.assign("/");
      }
    }
  }

  private async _closeAllTabs() {
    const tabsUpdated = await this._hasTabUpdated(this.taui.tabs);

    if (!tabsUpdated) {
      fireEvent(this as any, "route-page-remove-all-cache");
      fireEvent(this as any, "tabs-changed", []);
      setDefaultPanel(this as any, "/");
      navigate("/");
    }
  }

  private async _closeOtherTabs() {
    const tabs = this._tabs(this.taui.tabs)?.filter(
      (t) => t.url_path !== this.selectedPanel
    );
    const tabsUpdated = await this._hasTabUpdated(tabs);

    if (!tabsUpdated) {
      tabs?.forEach((t) => {
        fireEvent(this as any, "taui-tabs-close-tab", t.base_url_path);
      });
    }
  }

  _openCreatVodAssetList() {
    navigate(
      `/source/studio/latest-uploads?id=${this.taui.upload!.current!.assetId!}`
    );
  }

  _getCurrentUploadFilename(currentUpload, truncate: string) {
    if (currentUpload && currentUpload.r) {
      const resumableFile = currentUpload.r.files.find(
        (item) => item.file.mediaId === currentUpload.mediaId
      );
      if (resumableFile) {
        const fileName =
          truncate === "true"
            ? truncStringPortion(resumableFile.fileName, 8, 5)
            : resumableFile.fileName;

        return `${this.taui.localize(
          "ui.components.upload.uploading"
        )} ${fileName}`;
      }
    }
    return this.taui.localize("ui.components.upload.upload_in_progress");
  }

  private _closeAt(e) {
    e.stopPropagation();
    e.preventDefault();

    const tab = e.currentTarget.tab;
    if (tab) this._closeWithCheck(tab);
  }

  private async _closeWithCheck(tab): Promise<boolean> {
    if (tab) {
      const foundElement = this.taui.tabs?.find(
        (el) => el.url_path === tab.url_path
      );

      if (foundElement) {
        if (!foundElement.edited) {
          fireEvent(
            this as any,
            "taui-tabs-close-tab",
            foundElement.base_url_path
          );
        } else {
          const confirm = await showConfirmationDialog(this as any, {
            text: this.taui.localize("ui.components.tabs.unsaved_confirm"),
            confirmText: this.taui.localize("ui.common.leave"),
            dismissText: this.taui.localize("ui.common.stay"),
          });

          if (confirm) {
            fireEvent(
              this as any,
              "taui-tabs-close-tab",
              foundElement.base_url_path
            );
          } else {
            return false;
          }
        }
      }
    }

    return true;
  }

  private async _hasTabUpdated(tabs: TabInfo[]): Promise<boolean> {
    const tabsEdited = tabs?.filter((el) => el.edited);

    if (tabsEdited?.length > 0) {
      const confirm = await showConfirmationDialog(this as any, {
        text: this.taui.localize("ui.components.tabs.unsaved_confirm"),
        confirmText: this.taui.localize("ui.common.leave"),
        dismissText: this.taui.localize("ui.common.stay"),
      });

      return !confirm;
    }

    return false;
  }

  async handleTabSelected(ev) {
    const item = ev.detail.item;

    const newUrlPathSelected = item.getAttribute("url-path");

    if (newUrlPathSelected) {
      const tabSelected = this._tabs(this.taui.tabs)?.find(
        (tab) => tab.url_path === newUrlPathSelected
      );

      navigate(`${newUrlPathSelected}${tabSelected?.search || ""}`);
    }
  }

  private get _sidebarNarrow() {
    return this.narrow || this.taui.dockedSidebar === "always_hidden";
  }

  private _drawerClosed() {
    this._drawerOpen = false;
  }

  static get styles(): CSSResultGroup {
    return [
      tauiStyle,
      css`
        :host {
          color: var(--primary-text-color);
          /* remove the grey tap highlights in iOS on the fullscreen touch targets */
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          --mdc-drawer-width: 56px;
        }

        :host([expanded]) {
          --mdc-drawer-width: calc(256px + env(safe-area-inset-left));
        }
        :host([modal]) {
          --mdc-drawer-width: unset;
          --mdc-top-app-bar-width: unset;
        }
        partial-panel-resolver,
        taui-sidebar {
          /* allow a light tap highlight on the actual interface elements  */
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
        }

        div[slot="appContent"] {
          min-height: 100vh;
          position: relative;
          height: 100vh;
          overflow: hidden;
        }

        div[slot="appContent"] taui-linear-progress {
          position: sticky;
          top: var(--header-height);
          background: var(--app-header-toolbar-background-color);
          width: 100%;
          z-index: 1;
        }

        div[slot="appContent"] taui-top-app-bar-fixed {
          --taui-top-app-bar-fixed-row-height: calc(
            var(--header-height) - 1px /* border-bottom */
          );
          border-bottom: 1px solid var(--divider-color);
        }

        div[slot="appContent"] taui-top-app-bar-fixed .icon-button {
          width: 50px;
          min-width: auto;
          height: 100%;
          border: none;
          box-shadow: none;
        }
        div[slot="appContent"] taui-top-app-bar-fixed .icon-button taui-icon {
          font-size: 2em;
        }

        div[slot="appContent"] taui-top-app-bar-fixed taui-list-item taui-icon {
          display: inline-flex;
          width: 20px;
          margin: 0 10px;
          padding: 0;
        }

        div[slot="appContent"] taui-top-app-bar-fixed .actionItems {
          display: flex;
          align-items: center;
        }

        .uploading {
          display: flex;
          align-items: center;
          cursor: pointer;
          border-right: 1px solid var(--divider-color);
        }

        .uploading > taui-circular-progress {
          width: var(--header-height);
          height: var(--header-height);
        }

        .uploading > #details {
          padding-left: 5px;
          width: 100%;
          min-width: 150px;
          font-size: 0.66em;
        }

        taui-tabs {
          height: calc(
            var(--header-height) - 2px /* border-bottom for tab selected */
          );
          width: 100%;
        }
        paper-tab {
          height: calc(100% - 2px);
        }
        paper-tab.iron-selected {
          border-bottom: 2px solid var(--primary-color);
        }

        paper-tab .tab-content {
          display: flex;
          gap: 10px;
        }

        paper-tab .tab-content taui-icon {
          display: flex;
          align-items: center;
          --mdc-icon-button-size: 16px;
          --mdc-icon-size: 16px;
        }

        paper-tab .tab-content .errorTab {
          width: 14px;
          height: 14px;
          line-height: 14px;
          margin-top: 5px;
          padding: 2px;
          background-color: var(--error-color);
          color: var(--text-primary-color);
          border-radius: 25px;
          text-align: center;
        }
      `,
    ];
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-app-main": TauiAppMain;
  }
}
