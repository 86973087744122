import {
  applyThemesOnElement,
  invalidateThemeCache,
} from "../common/dom/apply_themes_on_element";
import { TAUIDomEvent } from "../common/dom/fire_event";
import { subscribeThemes } from "../data/ws-themes";
import { Constructor, TucanoAdminUI } from "../types";
import { storeState } from "../util/taui-pref-storage";
import { DEFAULT_THEME } from "../util/taui-themes";
import { TauiBaseEl } from "./taui-base-mixin";

declare global {
  // for add event listener
  interface HTMLElementEventMap {
    settheme: TAUIDomEvent<Partial<TucanoAdminUI["selectedTheme"]>>;
  }
  interface TAUIDomEvents {
    settheme: Partial<TucanoAdminUI["selectedTheme"]>;
  }
}

const mql = matchMedia("(prefers-color-scheme: dark)");

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    private _themeApplied = false;

    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);
      this.addEventListener("settheme", (ev) => {
        this._updateTaui({
          selectedTheme: {
            ...this.taui!.selectedTheme!,
            ...ev.detail,
          },
        });
        this._applyTheme(mql.matches);
        storeState(this.taui!);
      });
      mql.addListener((ev) => this._applyTheme(ev.matches));
      if (!this._themeApplied && mql.matches) {
        applyThemesOnElement(
          document.documentElement,
          {
            default_theme: DEFAULT_THEME,
            default_dark_theme: null,
            themes: {},
            darkMode: true,
            theme: DEFAULT_THEME,
          },
          undefined,
          undefined,
          true
        );
      }
    }

    protected tauiConnected() {
      super.tauiConnected();

      subscribeThemes(this.taui!.connection, (themes) => {
        this._themeApplied = true;
        this._updateTaui({ themes });
        invalidateThemeCache();
        this._applyTheme(mql.matches);
      });
    }

    private _applyTheme(darkPreferred: boolean) {
      if (!this.taui) {
        return;
      }

      let themeSettings: Partial<TucanoAdminUI["selectedTheme"]> =
        this.taui!.selectedTheme;

      const themeName =
        themeSettings?.theme ||
        (darkPreferred && this.taui.themes.default_dark_theme
          ? this.taui.themes.default_dark_theme
          : this.taui.themes.default_theme);

      let darkMode =
        themeSettings?.dark === undefined ? darkPreferred : themeSettings?.dark;

      const selectedTheme = themeName
        ? this.taui.themes.themes[themeName]
        : undefined;

      if (selectedTheme && darkMode && !selectedTheme.modes) {
        darkMode = false;
      }

      themeSettings = { ...this.taui.selectedTheme, dark: darkMode };
      this._updateTaui({
        themes: { ...this.taui.themes!, theme: themeName },
      });

      applyThemesOnElement(
        document.documentElement,
        this.taui.themes,
        themeName,
        themeSettings,
        true
      );

      if (darkMode !== this.taui.themes.darkMode) {
        this._updateTaui({
          themes: { ...this.taui.themes!, darkMode },
        });

        const schemeMeta = document.querySelector("meta[name=color-scheme]");
        if (schemeMeta) {
          schemeMeta.setAttribute(
            "content",
            darkMode
              ? "dark"
              : themeName === DEFAULT_THEME
              ? "light"
              : "dark light"
          );
        }
      }

      const themeMeta = document.querySelector("meta[name=theme-color]");
      const computedStyles = getComputedStyle(document.documentElement);
      const headerColor = computedStyles.getPropertyValue(
        "--app-header-background-color"
      );

      document.documentElement.style.backgroundColor =
        computedStyles.getPropertyValue("--primary-background-color");

      if (themeMeta) {
        if (!themeMeta.hasAttribute("default-content")) {
          themeMeta.setAttribute(
            "default-content",
            themeMeta.getAttribute("content")!
          );
        }
        const themeColor =
          headerColor?.trim() ||
          (themeMeta.getAttribute("default-content") as string);
        themeMeta.setAttribute("content", themeColor);
      }
    }
  };
