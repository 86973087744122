import { BackendService } from "@taui/shared/types/backend-service";
import {
  Dashboard,
  DashboardParams,
  DashboardPatchParams,
  DashboardSummary,
  DashboardUpdateParams,
  PanelConfiguration,
  PanelParameterConfiguration,
} from "@taui/shared/interfaces/openapi/dashboard-api";
import { TauiScope } from "@taui/shared/types/taui-scope";
import { TucanoAdminUI } from "../types";
import { fireEvent } from "../common/dom/fire_event";
import { getTenantIdBySlug } from "../util/taui-tenant";

export const setDefaultDashboard = (element: HTMLElement, id: number): void => {
  fireEvent(element, "taui-default-dashboard", { defaultDashboard: id });
};

export const getDefaultDashboard = (): Dashboard => ({
  idDashboard: -1,
  name: "default",
  panels: [
    {
      type: "STATIC",
      title:
        "ui.panel.dashboards.editor.card.single-stat.active_customers.title",
      gridPosition: { x: 0, y: 0, width: 4, height: 1 },
      metadata: {
        subType: "SINGLE_STAT",
        configuration: {
          key: "STATIC_SINGLE_STAT",
          icon: "fad:users",
          color: "#5773a8",
          resultPath:
            "result/tenantStats/[#tenantId#]/customerCountByStatus/ACTIVE",
          descriptionKey:
            "ui.panel.dashboards.editor.card.single-stat.active_customers.title",
          availableMaster: true,
        },
        values: {
          color: "#5773a8",
          icon: "fad:users",
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
    {
      type: "STATIC",
      title:
        "ui.panel.dashboards.editor.card.single-stat.average_profiles.title",
      gridPosition: { x: 4, y: 0, width: 4, height: 1 },
      metadata: {
        subType: "SINGLE_STAT",
        configuration: {
          key: "STATIC_SINGLE_STAT",
          icon: "fad:user",
          color: "#d8373a",
          resultPath: "result/tenantStats/[#tenantId#]/profileCountAverage",
          descriptionKey:
            "ui.panel.dashboards.editor.card.single-stat.average_profiles.title",
          availableMaster: false,
        },
        values: {
          color: "#d8373a",
          icon: "fad:user",
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
    {
      type: "STATIC",
      title: "ui.panel.dashboards.editor.card.table.logs-vod-ingest.title",
      gridPosition: { x: 0, y: 1, width: 9, height: 4 },
      metadata: {
        subType: "STATIC_TABLE",
        configuration: {
          tableAttributes: {
            service: BackendService.cms,
            path: "/vod/catalog/audit",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
              TauiScope.EXPERIENCE_CONTENT_VIEW,
              TauiScope.EXPERIENCE_CONTENT_EDIT,
            ],
            tools: ["result", "pagination"],
            column: [
              {
                path: ["title"],
                type: "status",
                width: "18px",
              },
              {
                label: "provider",
                path: ["catalog", "idDriver"],
              },
              {
                label: "method",
                path: ["additionalInfo", "method"],
              },
              {
                label: "catalog_name",
                path: ["catalog", "technicalName"],
                truncate: true,
                width: "150px",
              },
              {
                label: "start_time",
                path: ["details"],
                type: "firstKeyOfObject",
                format: "datetime",
              },
              {
                label: "end_time",
                path: ["time"],
                format: "datetime",
              },
              { label: "status", path: ["title"] },
              {
                label: "description",
                path: ["description"],
              },
            ],
            query: { catalog: { source: { $eq: true } } },
            order: [{ time: "desc" }],
            count: 10,
            translationPath: "ui.panel.dashboards.cards.table.logs.columns",
          },
          availableMaster: false,
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
    {
      type: "STATIC",
      title: "ui.panel.dashboards.editor.card.table.logs-vod-merge.title",
      gridPosition: { x: 0, y: 5, width: 9, height: 4 },
      metadata: {
        subType: "STATIC_TABLE",
        configuration: {
          tableAttributes: {
            service: BackendService.cms,
            path: "/vod/catalog/audit",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
              TauiScope.EXPERIENCE_CONTENT_VIEW,
              TauiScope.EXPERIENCE_CONTENT_EDIT,
            ],
            tools: ["result", "pagination"],
            column: [
              {
                path: ["title"],
                type: "status",
                width: "18px",
              },
              {
                label: "provider",
                path: ["catalog", "idDriver"],
              },
              {
                label: "method",
                path: ["additionalInfo", "method"],
              },
              {
                label: "catalog_name",
                path: ["catalog", "technicalName"],
                truncate: true,
                width: "150px",
              },
              {
                label: "start_time",
                path: ["details"],
                type: "firstKeyOfObject",
                format: "datetime",
              },
              {
                label: "end_time",
                path: ["time"],
                format: "datetime",
              },
              { label: "status", path: ["title"] },
              {
                label: "description",
                path: ["description"],
              },
            ],
            order: [{ time: "desc" }],
            query: { catalog: { source: { $eq: false } } },
            count: 10,
            translationPath: "ui.panel.dashboards.cards.table.logs.columns",
          },
          availableMaster: false,
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
    {
      type: "STATIC",
      title: "ui.panel.dashboards.editor.card.table.logs-epg-ingest.title",
      gridPosition: { x: 0, y: 9, width: 9, height: 4 },
      metadata: {
        subType: "STATIC_TABLE",
        configuration: {
          tableAttributes: {
            service: BackendService.cms,
            path: "/live/epg/provider/audit",
            scopes: [
              TauiScope.SOURCE_CONTENT_VIEW,
              TauiScope.SOURCE_CONTENT_EDIT,
            ],
            tools: ["result", "pagination"],
            column: [
              {
                path: ["title"],
                type: "status",
                width: "18px",
              },
              {
                label: "provider",
                path: ["epgProvider", "idDriver"],
              },
              {
                label: "method",
                path: ["additionalInfo", "method"],
              },
              {
                label: "provider_name",
                path: ["epgProvider", "name"],
              },
              {
                label: "start_time",
                path: ["details"],
                type: "firstKeyOfObject",
                format: "datetime",
              },
              {
                label: "end_time",
                path: ["time"],
                format: "datetime",
              },
              { label: "status", path: ["title"] },
              {
                label: "description",
                path: ["description"],
              },
            ],
            order: [{ time: "desc" }],
            count: 10,
            translationPath: "ui.panel.dashboards.cards.table.logs.columns",
          },
          availableMaster: false,
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
    {
      type: "STATIC",
      title: "ui.panel.dashboards.editor.card.table.customers.title",
      gridPosition: { x: 10, y: 1, width: 3, height: 4 },
      metadata: {
        subType: "STATIC_TABLE",
        configuration: {
          key: "STATIC_CUSTOMER_TABLE",
          tableAttributes: {
            tools: [],
            column: [
              { label: "status", path: ["name"] },
              { label: "count", path: ["value"] },
            ],
            order: [{ name: "asc" }],
            count: 10,
            dataList: [],
            dataListValue: "statsArray",
            translationPath:
              "ui.panel.dashboards.cards.table.customers.columns",
          },
          availableMaster: false,
        },
      },
      query: {},
      indices: [],
      window: { start: "", end: "" },
      operator: "SUM",
    },
  ],
});

export const dashboardListDashboards = async (
  taui: TucanoAdminUI,
  params?: {
    tenant_id?: number;
    query?: string;
    order?: string;
    page?: number;
    count?: number;
  }
) => {
  if (taui.tenantMaster) {
    const tenantId = getTenantIdBySlug(taui, taui.selectedTenant);
    if (tenantId)
      params = {
        ...(params || {}),
        tenant_id: tenantId,
      };
  }

  return taui.callApi<{
    dashboards?: DashboardSummary[];
  }>("GET", BackendService.dashboard, "/dashboard", params);
};

export const dashboardGetDashboard = async (
  taui: TucanoAdminUI,
  idDashboard: number
) =>
  taui.callApi<Dashboard>(
    "GET",
    BackendService.dashboard,
    `/dashboard/${idDashboard}`
  );

export const dashboardCreateDashboard = async (
  taui: TucanoAdminUI,
  params: DashboardParams
) => {
  if (taui.tenantMaster) {
    const tenantId = getTenantIdBySlug(taui, taui.selectedTenant);
    if (tenantId)
      params = {
        ...(params || {}),
        tenantId: tenantId,
      };
  }

  return taui.callApi<Dashboard>(
    "POST",
    BackendService.dashboard,
    "/dashboard",
    params
  );
};

export const dashboardUpdateDashboard = async (
  taui: TucanoAdminUI,
  idDashboard: number,
  params: DashboardUpdateParams
) =>
  taui.callApi<Dashboard>(
    "PUT",
    BackendService.dashboard,
    `/dashboard/${idDashboard}`,
    params
  );

export const dashboardPatchDashboard = async (
  taui: TucanoAdminUI,
  idDashboard: number,
  params: DashboardPatchParams
) =>
  taui.callApi<Dashboard>(
    "PATCH",
    BackendService.dashboard,
    `/dashboard/${idDashboard}`,
    params
  );

export const dashboardDeleteDashboard = async (
  taui: TucanoAdminUI,
  idDashboard: number
) =>
  taui.callApi<void>(
    "DELETE",
    BackendService.dashboard,
    `/dashboard/${idDashboard}`
  );

export const dashboardGetDashboardPanelConfiguration = async (
  taui: TucanoAdminUI
) =>
  taui.callApi<PanelConfiguration[]>(
    "GET",
    BackendService.dashboard,
    "/panel/configuration"
  );

export const dashboardGetDashboardPanelConfigurationTypeParams = async (
  taui: TucanoAdminUI,
  type: string,
  params?: any
) =>
  taui.callApi<PanelParameterConfiguration[]>(
    "GET",
    BackendService.dashboard,
    `/panel/configuration/${type}/params`,
    params
  );
