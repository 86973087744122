import { PropertyValues } from "lit";
import { customElement, property } from "lit/decorators";
import { deepActiveElement } from "../common/dom/deep-active-element";
import { deepEqual } from "../common/util/deep-equal";
import { getDefaultPanel, setDefaultPanel } from "../data/panel";
import { Panels, TucanoAdminUI } from "../types";
import { removeLaunchScreen } from "../util/launch-screen";
import {
  TauiRouterPage,
  RouteOptions,
  RouterOptions,
} from "./taui-router-page";

const CACHE_URL_PATHS = ["dashboards"];

const COMPONENTS = {
  dashboards: () => import("../panels/dashboards/taui-panel-dashboards"),
  source: () => import("../panels/source/taui-panel-source"),
  enrich: () => import("../panels/enrich/taui-panel-enrich"),
  experience: () => import("../panels/experience/taui-panel-experience"),
  publish: () => import("../panels/publish/taui-panel-publish"),
  settings: () => import("../panels/settings/taui-panel-settings"),
  cms: () => import("../panels/cms/taui-panel-cms"),
};

@customElement("partial-panel-resolver")
class PartialPanelResolver extends TauiRouterPage {
  @property({ attribute: false }) public taui!: TucanoAdminUI;

  @property({ type: Boolean }) public narrow = false;

  @property() public cache;

  private _disconnectedPanel?: HTMLElement;

  private _disconnectedActiveElement?: HTMLElement;

  private _hiddenTimeout?: number;

  protected firstUpdated(changedProps: PropertyValues) {
    super.firstUpdated(changedProps);

    // Attach listeners for visibility
    document.addEventListener(
      "visibilitychange",
      () => this._checkVisibility(),
      false
    );
    document.addEventListener("resume", () => this._checkVisibility());
  }

  public willUpdate(changedProps: PropertyValues) {
    super.willUpdate(changedProps);

    if (!changedProps.has("taui")) {
      return;
    }

    const oldTaui = changedProps.get("taui") as this["taui"];

    if (this.taui.panels && (!oldTaui || oldTaui.panels !== this.taui.panels)) {
      this._updateRoutes(oldTaui?.panels);
    }
  }

  protected createLoadingScreen() {
    const el = super.createLoadingScreen();
    el.rootnav = true;
    el.taui = this.taui;
    el.narrow = this.narrow;
    return el;
  }

  protected updatePageEl(el) {
    const taui = this.taui;

    el.taui = taui;
    el.narrow = this.narrow;
    el.route = this.routeTail;
    el.cache = this.cache;
    el.panel = taui.panels[this._currentPage];
  }

  private _checkVisibility() {
    if (this.taui.suspendWhenHidden === false) {
      return;
    }

    if (document.hidden) {
      this._onHidden();
    } else {
      this._onVisible();
    }
  }

  private getRoutes(panels: Panels): RouterOptions {
    const routes: RouterOptions["routes"] = {};
    Object.values(panels).forEach((panel) => {
      const data: RouteOptions = {
        tag: `taui-panel-${panel.component_name}`,
        cache: CACHE_URL_PATHS.includes(panel.url_path),
      };
      if (panel.component_name in COMPONENTS) {
        data.load = COMPONENTS[panel.component_name];
      }
      routes[panel.url_path] = data;
    });

    routes.cms = {
      tag: "taui-panel-cms",
      load: COMPONENTS.cms,
    };

    return {
      beforeRender: (page) => {
        if (!page || !routes[page]) {
          return getDefaultPanel(this.taui).url_path;
        }
        return undefined;
      },
      showLoading: true,
      cacheName: () => `/`,
      routes,
    };
  }

  private _onHidden() {
    this._hiddenTimeout = window.setTimeout(() => {
      this._hiddenTimeout = undefined;
      // setTimeout can be delayed in the background and only fire
      // when we switch to the tab or app again (Hey Android!)
      if (!document.hidden) {
        return;
      }
      if (this.lastChild) {
        this._disconnectedPanel = this.lastChild as HTMLElement;
        const activeEl = deepActiveElement(
          this._disconnectedPanel.shadowRoot || undefined
        );
        if (activeEl instanceof HTMLElement) {
          this._disconnectedActiveElement = activeEl;
        }
        this.removeChild(this.lastChild);
      }
    }, 300000);
    window.addEventListener("focus", () => this._onVisible(), { once: true });
  }

  private _onVisible() {
    if (this._hiddenTimeout) {
      clearTimeout(this._hiddenTimeout);
      this._hiddenTimeout = undefined;
    }
    if (this._disconnectedPanel) {
      this.appendChild(this._disconnectedPanel);
      this._disconnectedPanel = undefined;
    }
    if (this._disconnectedActiveElement) {
      this._disconnectedActiveElement.focus();
      this._disconnectedActiveElement = undefined;
    }
  }

  private async _updateRoutes(oldPanels?: TucanoAdminUI["panels"]) {
    this.routerOptions = this.getRoutes(this.taui.panels);

    if (
      !oldPanels ||
      !deepEqual(
        oldPanels[this._currentPage],
        this.taui.panels[this._currentPage]
      )
    ) {
      await this.rebuild();
      await this.pageRendered;
      removeLaunchScreen();
    }
  }

  protected storeRoute(route) {
    const urlPath = route
      ? `${route?.prefix || ""}${route?.path || ""}${route?.search || ""}`
      : "";

    if (!urlPath || urlPath === this.taui.selectedPanel) {
      return;
    }

    setDefaultPanel(this, urlPath);
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "partial-panel-resolver": PartialPanelResolver;
  }
}
