/** Panel to show when no panel is picked. */
import { fireEvent } from "../common/dom/fire_event";
import { PanelInfo, TucanoAdminUI } from "../types";

export const DEFAULT_PANEL = "dashboards";

export const getStorageDefaultPanelUrlPath = (): string =>
  localStorage.selectedPanel
    ? JSON.parse(localStorage.selectedPanel)
    : DEFAULT_PANEL;

export const setDefaultPanel = (
  element: HTMLElement,
  urlPath: string
): void => {
  fireEvent(element, "taui-selected-panel", { selectedPanel: urlPath });
};

export const getDefaultPanel = (taui: TucanoAdminUI): PanelInfo =>
  taui.panels[taui.selectedPanel]
    ? taui.panels[taui.selectedPanel]
    : taui.panels[DEFAULT_PANEL];
