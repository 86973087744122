import {
  css,
  CSSResultGroup,
  html,
  LitElement,
  nothing,
  PropertyValues,
} from "lit";
import { customElement, property, state } from "lit/decorators";
import { unsafeSVG } from "lit/directives/unsafe-svg";
import { debounce } from "../common/util/debounce";
import {
  checkCacheVersion,
  ChunksIcons,
  DatabaseIcon,
  FA_PREFIXES,
  findIconChunk,
  getIcon,
  Icons,
  ICON_PREFIXES,
  writeCache,
} from "../data/iconsets";
import "./taui-fontawesome";
import "./taui-svg-icon";
import { fireEvent } from "../common/dom/fire_event";

interface DeprecatedIcon {
  [key: string]: {
    removeIn: string;
    newName?: string;
  };
}

const mdiDeprecatedIcons: DeprecatedIcon = {};

const chunks: ChunksIcons = { fad: {}, taui: {} };

checkCacheVersion();

const debouncedWriteCache = debounce(() => writeCache(chunks), 2000);

export interface CachedIcons {
  [key: string]: Record<string, DatabaseIcon>;
}

const cachedIcons: CachedIcons = { fad: {}, taui: {} };

@customElement("taui-icon")
export class TauiIcon extends LitElement {
  @property() public icon?: string;

  @state() private _path?: string | string[];

  @state() private _viewBox?: string;

  @state() private _legacy = false;

  @state() private _fontawesome = false;

  @state() private _customIcon = false;

  @state() private _svg;

  @property({ type: Boolean, attribute: "fixed-width" })
  public fixedWidth?: boolean = false;

  public willUpdate(changedProps: PropertyValues) {
    super.willUpdate(changedProps);

    if (changedProps.has("icon")) {
      this._path = undefined;
      this._viewBox = undefined;
      this._loadIcon();
    }
  }

  protected render() {
    if (!this.icon) {
      return nothing;
    }
    if (this._legacy) {
      return html`<!-- @ts-ignore we don't provice the iron-icon element -->
        <iron-icon .icon=${this.icon}></iron-icon>`;
    }

    if (this._fontawesome) {
      return html`<taui-fontawesome
        .icon=${this.icon}
        .path=${this._path}
        .viewBox=${this._viewBox}
        .fixedWidth=${this.fixedWidth}
      ></taui-fontawesome>`;
    }

    if (this._customIcon) {
      return html`${unsafeSVG(this._svg)}`;
    }

    return html`<taui-svg-icon
      .path=${this._path}
      .viewBox=${this._viewBox}
    ></taui-svg-icon>`;
  }

  private async _loadIcon() {
    if (!this.icon) {
      return;
    }
    const [iconPrefix, origIconName] = this.icon.split(":", 2);

    let iconName = origIconName;

    if (!iconPrefix || !iconName) {
      return;
    }

    if (!ICON_PREFIXES.includes(iconPrefix)) {
      this._legacy = true;
      return;
    }

    if (FA_PREFIXES.includes(iconPrefix)) {
      this._fontawesome = true;
    }

    this._legacy = false;

    if (iconName in mdiDeprecatedIcons) {
      const deprecatedIcon = mdiDeprecatedIcons[iconName];
      let message: string;

      if (deprecatedIcon.newName) {
        message = `Icon ${iconPrefix}:${iconName} was renamed to ${iconPrefix}:${deprecatedIcon.newName}, please change your config, it will be removed in version ${deprecatedIcon.removeIn}.`;
        iconName = deprecatedIcon.newName!;
      } else {
        message = `Icon ${iconPrefix}:${iconName} was removed from FontAwesome, please replace this icon with an other icon in your config, it will be removed in version ${deprecatedIcon.removeIn}.`;
      }

      fireEvent(this, "write_log", {
        level: "warning",
        message,
      });
    }

    if (
      iconName in cachedIcons[iconPrefix] &&
      cachedIcons[iconPrefix][iconName] !== undefined
    ) {
      if (cachedIcons[iconPrefix][iconName].svg) {
        this._customIcon = true;
        this._svg = cachedIcons[iconPrefix][iconName].svg;
      } else {
        this._path = cachedIcons[iconPrefix][iconName].path;
        this._viewBox = cachedIcons[iconPrefix][iconName].viewBox;
      }
      return;
    }

    let databaseIcon: DatabaseIcon | undefined;
    try {
      databaseIcon = await getIcon(iconPrefix, iconName);
    } catch (_err) {
      // Firefox in private mode doesn't support IDB
      // iOS Safari sometimes doesn't open the DB
      databaseIcon = undefined;
    }

    if (databaseIcon) {
      if (databaseIcon.svg) {
        this._customIcon = true;
        this._svg = databaseIcon.svg;
      } else {
        this._path = databaseIcon.path;
        this._viewBox = databaseIcon.viewBox;
      }
      cachedIcons[iconPrefix][iconName] = databaseIcon;
      return;
    }
    const chunk = findIconChunk(iconPrefix, iconName);

    if (chunk in chunks) {
      this._setPath(chunks[iconPrefix][chunk], iconPrefix, iconName);
      return;
    }

    const iconPromise = fetch(`/static/${iconPrefix}/${chunk}.json`).then(
      (response) => response.json()
    );
    chunks[iconPrefix][chunk] = iconPromise;
    this._setPath(iconPromise, iconPrefix, iconName);
    debouncedWriteCache();
  }

  private async _setPath(
    promise: Promise<Icons>,
    iconPrefix: string,
    iconName: string
  ) {
    const iconPack = await promise;

    if (iconPack[iconName]) {
      if (Object.prototype.hasOwnProperty.call(iconPack[iconName], "svg")) {
        this._customIcon = true;
        this._svg = iconPack[iconName].svg;
      } else {
        this._path = iconPack[iconName].path;
        this._viewBox = iconPack[iconName].viewBox;
      }
    }
    cachedIcons[iconPrefix][iconName] = iconPack[iconName];
  }

  static get styles(): CSSResultGroup {
    return css`
      :host {
        fill: currentcolor;
      }
    `;
  }
}
declare global {
  interface HTMLElementTagNameMap {
    "taui-icon": TauiIcon;
  }
}
