import { fireEvent, TAUIDomEvent } from "../common/dom/fire_event";
import { navigate } from "../common/navigate";
import { DEFAULT_PANEL } from "../data/panel";
import { Constructor, TabInfo, TucanoAdminUI } from "../types";
import { storeState } from "../util/taui-pref-storage";
import { TauiBaseEl } from "./taui-base-mixin";

interface SelectedPanelParams {
  selectedPanel: TucanoAdminUI["selectedPanel"];
}

declare global {
  // for fire event
  interface TAUIDomEvents {
    "taui-selected-panel": SelectedPanelParams;
    "taui-tabs-tab-edited": string;
    "taui-tabs-tab-saved": string;
    "taui-tabs-tab-error": {
      key: string;
      error?: any;
    };
    "taui-tabs-tab-replace-tab-key": {
      oldKey: string;
      key: string;
    };
    "taui-tabs-refresh-breadcrumb-tab": {
      key: string;
      title: string;
      ctx: HTMLElement;
    };
    "taui-tabs-close-tab": string;
    "taui-tabs-add-tab": TabInfo;
    "tabs-changed": TabInfo[];
  }
  // for add event listener
  interface HTMLElementEventMap {
    "taui-selected-panel": TAUIDomEvent<SelectedPanelParams>;
  }
}

export default <T extends Constructor<TauiBaseEl>>(superClass: T) =>
  class extends superClass {
    protected firstUpdated(changedProps) {
      super.firstUpdated(changedProps);

      this.addEventListener("tabs-changed", (ev) => {
        this._tabsChanged(ev.detail);
      });

      this.addEventListener("taui-selected-panel", (ev) => {
        this._updateTaui({
          selectedPanel: ev.detail.selectedPanel,
        });
        storeState(this.taui!);
      });

      this.addEventListener("taui-tabs-tab-edited", (ev) => {
        this._setPanelEdited(ev.detail);
      });

      this.addEventListener("taui-tabs-tab-saved", (ev) => {
        this._setPanelSaved(ev.detail);
      });

      this.addEventListener("taui-tabs-tab-error", (ev) => {
        this._setPanelError(ev.detail.key, ev.detail.error);
      });

      this.addEventListener("taui-tabs-tab-replace-tab-key", (ev) => {
        this._replaceTabKey(ev.detail);
      });

      this.addEventListener("taui-tabs-refresh-breadcrumb-tab", (ev) => {
        const key = ev.detail.key;
        const title = ev.detail.title;
        const ctx = ev.detail.ctx;

        this._updatePanelName(key, title);

        ctx.breadcrumbPath[ctx.breadcrumbPath.length - 1] = {
          ...(ctx.breadcrumbPath[ctx.breadcrumbPath.length - 1] || {}),
          label: title,
        };
        ctx.breadcrumbPath = [...ctx.breadcrumbPath];
      });

      this.addEventListener("taui-tabs-close-tab", (ev) => {
        this._closeTab(ev.detail);
      });

      this.addEventListener("taui-tabs-add-tab", (ev) => {
        this._addTab(ev.detail);
      });
    }

    private _tabsChanged(tabs) {
      this._updateTaui({
        tabs: tabs,
      });
      storeState(this.taui!);
    }

    private _addTab(tab: TabInfo) {
      const actualTabSession = this.taui!.tabs;
      const findTab = actualTabSession.find(
        (item) => item.base_url_path === tab.base_url_path
      );

      if (!findTab) {
        this._tabsChanged([
          ...(actualTabSession || []),
          {
            index: actualTabSession.length,
            url_path: tab.url_path,
            base_url_path: tab.base_url_path,
            search: tab.search,
            edited: false,
            error: false,
          },
        ]);
      } else {
        this._tabsChanged(
          actualTabSession?.map((t) => {
            if (t.base_url_path === tab.base_url_path) {
              return {
                ...t,
                url_path: tab.url_path,
                base_url_path: tab.base_url_path,
              };
            }
            return t;
          }) || []
        );
      }
    }

    private _setPanelEdited(base_url_path: string) {
      this._tabsChanged(
        this.taui!.tabs?.map((obj) =>
          obj.base_url_path === base_url_path ? { ...obj, edited: true } : obj
        ) || []
      );
    }

    private _setPanelSaved(base_url_path: string) {
      this._tabsChanged(
        this.taui!.tabs?.map((obj) =>
          obj.base_url_path === base_url_path ? { ...obj, edited: false } : obj
        ) || []
      );
    }

    private _setPanelError(base_url_path: string, error = true) {
      this._tabsChanged(
        this.taui!.tabs?.map((obj) =>
          obj.base_url_path === base_url_path ? { ...obj, error: error } : obj
        ) || []
      );
    }

    private _updatePanelName(base_url_path: string, name: string) {
      this._tabsChanged(
        this.taui!.tabs?.map((obj) =>
          obj.base_url_path === base_url_path
            ? { ...obj, name, edited: false }
            : obj
        ) || []
      );
    }

    private _replaceTabKey(params: { oldKey: string; key: string }) {
      this._tabsChanged(
        this.taui!.tabs?.map((obj) =>
          obj.base_url_path === params.oldKey
            ? { ...obj, base_url_path: params.key }
            : obj
        ) || []
      );

      fireEvent(this as any, "route-page-remove-cache", params.oldKey);

      navigate(params.key, { replace: true });
    }

    private async _closeTab(base_url_path: string) {
      const foundElement = this.taui?.tabs?.find(
        (el) => el.base_url_path === base_url_path
      );

      if (foundElement) {
        fireEvent(this as any, "route-page-remove-cache", base_url_path);
        const urlPath = `${foundElement.url_path}${foundElement.search || ""}`;

        if (this.taui?.selectedPanel === urlPath) {
          const currentIdTabSelected = this.taui?.tabs?.findIndex(
            (reg) =>
              `${reg.url_path}${reg.search || ""}` === this.taui!.selectedPanel
          );
          const previousTabSelected =
            this.taui?.tabs?.[currentIdTabSelected - 1];

          navigate(
            previousTabSelected?.url_path
              ? `${previousTabSelected.url_path}${
                  previousTabSelected.search || ""
                }`
              : `/${DEFAULT_PANEL}`
          );
        }

        this._tabsChanged(
          this.taui?.tabs?.filter((reg) => reg?.base_url_path !== base_url_path)
        );
      }
    }
  };
