import "@polymer/polymer/lib/elements/custom-style";
import { derivedStyles } from "./styles";

export const DEFAULT_PRIMARY_COLOR = "#e42a2b";
export const DEFAULT_ACCENT_COLOR = "#e95b28";

const documentContainer = document.createElement("template");
documentContainer.setAttribute("style", "display: none;");

documentContainer.innerHTML = `<custom-style>
  <style>
    /*
      TAUI default styles.

      In Polymer 2.0, default styles should to be set on the html selector.
      (Setting all default styles only on body breaks shadyCSS polyfill.)
    */
    html {
        font-size: 14px;
        height: 100vh;

        /* text */
        --primary-text-color: #212121;
        --secondary-text-color: #727272;
        --text-primary-color: #ffffff;
        --text-light-primary-color: #212121;
        --disabled-text-color: #bdbdbd;
        
        /* main interface color */
        --primary-color: ${DEFAULT_PRIMARY_COLOR};
        --dark-primary-color: ${DEFAULT_PRIMARY_COLOR};
        --light-primary-color: ${DEFAULT_PRIMARY_COLOR};
        --accent-color: ${DEFAULT_ACCENT_COLOR};
        --divider-color: rgba(0, 0, 0, .12);
        
        --error-color: #d8373a;
        --warning-color: #FF9800;
        --success-color: #0f9d58;
        --info-color: #4285f4;
        
        /* background and sidebar */
        --card-background-color: #ffffff;
        --primary-background-color: #fafafa;
        --secondary-background-color: #e5e5e5; /* behind the cards on state */
        
        /* for header */
        --header-height: 56px;
        
        /* states */
        --state-icon-color: #44739e;
        /* an active state is anything that would require attention */
        --state-icon-active-color: #FDD835;
        /* an error state is anything that would be considered an error */
        /* --state-icon-error-color: #db4437; derived from error-color */

        --scrollbar-thumb-color: rgb(194, 194, 194);

        --app-loader-background: #edf2f7;
        --app-select-color: #84bcff;
        --app-progress-bar-color: var(--info-color);
        --app-edited-field-color: #feffc8;
        
        /*
          Paper-styles color.html dependency is stripped on build.
          When a default paper-style color is used, it needs to be copied
          from paper-styles/color.html to here.
        */        
        --paper-grey-50: #fafafa; /* default for: --mwc-switch-unchecked-button-color */
        --paper-grey-200: #eeeeee;  /* for taui-date-picker-style */
        --paper-grey-500: #9e9e9e;  /* --label-badge-grey */
        
        --paper-green-400: #66bb6a;
        --paper-blue-400: #42a5f5;
        --paper-orange-400: #ffa726;
          
        /* buttons */
        --paper-button-color: #006bb5;
        --paper-button-text-color: #fff;
        --paper-button-hover-color: #005999;
        --paper-button-active-color: #004a80;
        
        /* opacity for dark text on a light background */
        --dark-divider-opacity: 0.12;
        --dark-disabled-opacity: 0.38; /* or hint text or icon */
        --dark-secondary-opacity: 0.54;
        --dark-primary-opacity: 0.87;
        
        /* opacity for light text on a dark background */
        --light-divider-opacity: 0.12;
        --light-disabled-opacity: 0.3; /* or hint text or icon */
        --light-secondary-opacity: 0.7;
        --light-primary-opacity: 1.0;
        
        /* rgb */
        --rgb-primary-color: 228, 42, 43;
        --rgb-accent-color: 233, 91, 40;
        --rgb-success-color: 15, 157, 88;
        --rgb-primary-text-color: 33, 33, 33;
        --rgb-secondary-text-color: 114, 114, 114;
        --rgb-text-primary-color: 255, 255, 255;
        --rgb-card-background-color: 255, 255, 255;
        
        /* input components */
        --input-idle-line-color: rgba(0, 0, 0, 0.42);
        --input-hover-line-color: rgba(0, 0, 0, 0.87);
        --input-disabled-line-color: rgba(0, 0, 0, 0.06);
        --input-outlined-idle-border-color: rgba(0, 0, 0, 0.38);
        --input-outlined-hover-border-color: rgba(0, 0, 0, 0.87);
        --input-outlined-disabled-border-color: rgba(0, 0, 0, 0.06);
        --input-fill-color: rgb(245, 245, 245);
        --input-disabled-fill-color: rgb(250, 250, 250);
        --input-ink-color: rgba(0, 0, 0, 0.87);
        --input-label-ink-color: rgba(0, 0, 0, 0.6);
        --input-disabled-ink-color: rgba(0, 0, 0, 0.37);
        --input-dropdown-icon-color: rgba(0, 0, 0, 0.54);

        /* Vaadin typography */
        --material-h6-font-size: 1.25rem;
        --material-small-font-size: 0.875rem;
        --material-caption-font-size: 0.75rem;
        --material-button-font-size: 0.875rem;

         /* Paper shadow */
        --shadow-transition: {
          transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1);
        };
        --shadow-none: {
          box-shadow: none;
        };
        /* from http://codepen.io/shyndman/pen/c5394ddf2e8b2a5c9185904b57421cdb */
        --shadow-elevation-2dp: {
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
                      0 1px 5px 0 rgba(0, 0, 0, 0.12),
                      0 3px 1px -2px rgba(0, 0, 0, 0.2);
        };
        --shadow-elevation-3dp: {
          box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
                      0 1px 8px 0 rgba(0, 0, 0, 0.12),
                      0 3px 3px -2px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-4dp: {
          box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
                      0 1px 10px 0 rgba(0, 0, 0, 0.12),
                      0 2px 4px -1px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-6dp: {
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14),
                      0 1px 18px 0 rgba(0, 0, 0, 0.12),
                      0 3px 5px -1px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-8dp: {
          box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
                      0 3px 14px 2px rgba(0, 0, 0, 0.12),
                      0 5px 5px -3px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-12dp: {
          box-shadow: 0 12px 16px 1px rgba(0, 0, 0, 0.14),
                      0 4px 22px 3px rgba(0, 0, 0, 0.12),
                      0 6px 7px -4px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-16dp: {
          box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
                      0  6px 30px 5px rgba(0, 0, 0, 0.12),
                      0  8px 10px -5px rgba(0, 0, 0, 0.4);
        };
        --shadow-elevation-24dp: {
          box-shadow: 0 24px 38px 3px rgba(0, 0, 0, 0.14),
                      0 9px 46px 8px rgba(0, 0, 0, 0.12),
                      0 11px 15px -7px rgba(0, 0, 0, 0.4);
        };
        /* Paper typography Styles */
        --paper-font-common-base: {
          font-family: 'Roboto', 'Noto', sans-serif;
          -webkit-font-smoothing: antialiased;
        };
        --paper-font-common-code: {
          font-family: 'Roboto Mono', 'Consolas', 'Menlo', monospace;
          -webkit-font-smoothing: antialiased;
        };
        --paper-font-common-expensive-kerning: {
          text-rendering: optimizeLegibility;
        };
        --paper-font-common-nowrap: {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        };
        /* Material Font Styles */
        --paper-font-display4: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 112px;
          font-weight: 300;
          letter-spacing: -.044em;
          line-height: 120px;
        };
        --paper-font-display3: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 56px;
          font-weight: 400;
          letter-spacing: -.026em;
          line-height: 60px;
        };
        --paper-font-display2: {
          @apply --paper-font-common-base;
          font-size: 45px;
          font-weight: 400;
          letter-spacing: -.018em;
          line-height: 48px;
        };
        --paper-font-display1: {
          @apply --paper-font-common-base;
          font-size: 34px;
          font-weight: 400;
          letter-spacing: -.01em;
          line-height: 40px;
        };
        --paper-font-headline: {
          @apply --paper-font-common-base;
          font-size: 24px;
          font-weight: 400;
          letter-spacing: -.012em;
          line-height: 32px;
        };
        --paper-font-title: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
        };
        --paper-font-subhead: {
          @apply --paper-font-common-base;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
        };
        --paper-font-body2: {
          @apply --paper-font-common-base;
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
        };
        --paper-font-body1: {
          @apply --paper-font-common-base;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        };
        --paper-font-caption: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: 0.011em;
          line-height: 20px;
        };
        --paper-font-menu: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 13px;
          font-weight: 500;
          line-height: 24px;
        };
        --paper-font-button: {
          @apply --paper-font-common-base;
          @apply --paper-font-common-nowrap;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.018em;
          line-height: 24px;
          text-transform: uppercase;
        };
        --paper-font-code2: {
          @apply --paper-font-common-code;
          font-size: 14px;
          font-weight: 700;
          line-height: 20px;
        };
        --paper-font-code1: {
          @apply --paper-font-common-code;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
        };
        
        ${Object.entries(derivedStyles)
          .map(([key, value]) => `--${key}: ${value};`)
          .join("")}
    }
    
    body #tooltipDragAndDrop {
        position: absolute;
        padding: 5px 10px;
        border-radius: 3px;
        background-color: #555;
        color: #fff;
        text-align: center;
        font-weight: normal;
    }
  </style>
</custom-style>`;

document.head.appendChild(documentContainer.content);
