import { TucanoAdminUI } from "../types";
import { DEFAULT_TENANT } from "./taui-tenant";
import { DEFAULT_THEME } from "./taui-themes";

const STORED_STATE = [
  "dockedSidebar",
  "selectedTheme",
  "selectedLanguage",
  "vibrate",
  "defaultDashboard",
  "previousIdentity",
  "selectedPanel",
  "tabs",
  "dynamicColumn",
  "idsUpload",
  "suspendWhenHidden",
  "enableShortcuts",
  "selectedTenant",
  "tenants",
];
const STORAGE = window.localStorage || {};

export function storeState(taui: TucanoAdminUI) {
  try {
    STORED_STATE.forEach((key) => {
      const value = taui[key];
      STORAGE[key] = JSON.stringify(value === undefined ? null : value);
    });
  } catch (err) {
    // Safari throws exception in private mode
  }
}

export function getState() {
  const state: any = {};

  STORED_STATE.forEach((key) => {
    if (key in STORAGE) {
      let value = JSON.parse(STORAGE[key]);
      // selectedTheme went from string to object on 20200718
      if (key === "selectedTheme" && typeof value === "string") {
        value = { theme: value };
      }
      // dockedSidebar went from boolean to enum on 20190720
      if (key === "dockedSidebar" && typeof value === "boolean") {
        value = value ? "docked" : "auto";
      }
      state[key] = value;
    }
  });

  return state;
}

export function clearState(full = false) {
  let currentState;
  if (!full) currentState = getState();

  // STORAGE is an object if localStorage not available.
  if (STORAGE.clear) {
    STORAGE.clear();
  }

  // Restore keeping state
  if (currentState) {
    STORAGE.previousIdentity = JSON.stringify(
      currentState?.previousIdentity === undefined
        ? null
        : currentState?.previousIdentity
    );
    STORAGE.selectedPanel = JSON.stringify(
      currentState?.selectedPanel === undefined
        ? null
        : currentState?.selectedPanel
    );
    STORAGE.tabs = JSON.stringify(
      currentState?.tabs === undefined ? [] : currentState?.tabs
    );
    STORAGE.dynamicColumn = JSON.stringify(
      currentState?.dynamicColumn === undefined
        ? []
        : currentState?.dynamicColumn
    );

    STORAGE.idsUpload = JSON.stringify(
      currentState?.idsUpload === undefined ? [] : currentState?.idsUpload
    );

    STORAGE.selectedTenant = JSON.stringify(
      currentState?.selectedTenant === undefined
        ? DEFAULT_TENANT
        : currentState?.selectedTenant
    );

    STORAGE.tenants = JSON.stringify(
      currentState?.tenants === undefined ? [] : currentState?.tenants
    );

    STORAGE.dockedSidebar = JSON.stringify(
      currentState?.dockedSidebar === undefined
        ? "docked"
        : currentState?.dockedSidebar
    );

    STORAGE.enableShortcuts = JSON.stringify(
      currentState?.enableShortcuts === undefined
        ? true
        : currentState?.enableShortcuts
    );

    STORAGE.selectedTheme = JSON.stringify(
      currentState?.selectedTheme === undefined
        ? { theme: DEFAULT_THEME }
        : currentState?.selectedTheme
    );
  }
}
