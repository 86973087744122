import { TopAppBarFixedBase } from "@material/mwc-top-app-bar-fixed/mwc-top-app-bar-fixed-base";
import { styles } from "@material/mwc-top-app-bar/mwc-top-app-bar.css";
import { css } from "lit";
import { customElement, property } from "lit/decorators";

let drawerContent: HTMLElement | undefined;

@customElement("taui-top-app-bar-fixed")
export class TauiTopAppBarFixed extends TopAppBarFixedBase {
  private get _drawerContent() {
    if (!drawerContent) {
      drawerContent = document
        .querySelector("taui-app")!
        .renderRoot.querySelector("taui-app-main")!
        .renderRoot.querySelector("taui-drawer")!
        .renderRoot.querySelector(".mdc-drawer-app-content") as HTMLElement;
    }
    return drawerContent;
  }

  @property({ type: Object })
  get scrollTarget() {
    return this._scrollTarget || this._drawerContent || window;
  }

  protected updateRootPosition() {
    // do nothing.
  }

  static override styles = [
    styles,
    css`
      .mdc-top-app-bar {
        position: sticky;
        top: 0;
      }
      .mdc-top-app-bar__row {
        height: var(--header-height);
        border-bottom: var(--app-header-border-bottom);
      }
      .mdc-top-app-bar--fixed-adjust {
        padding-top: 0;
      }
      .mdc-top-app-bar {
        --mdc-typography-headline6-font-weight: 400;
        color: var(--app-header-text-color, var(--mdc-theme-on-primary, #fff));
        background-color: var(
          --app-header-background-color,
          var(--mdc-theme-primary)
        );
      }
      .mdc-top-app-bar .mdc-top-app-bar__section .mdc-top-app-bar__title {
        padding-left: 0px;
      }
      .mdc-top-app-bar .mdc-top-app-bar__row {
        height: var(--taui-top-app-bar-fixed-row-height, 64px);
      }
      .mdc-top-app-bar__section--align-start,
      .mdc-top-app-bar .mdc-top-app-bar__section .mdc-top-app-bar__title {
        width: 100%;
      }
      .mdc-top-app-bar .mdc-top-app-bar__section {
        min-width: auto;
        padding: 0;
      }
    `,
  ];
}

declare global {
  interface HTMLElementTagNameMap {
    "taui-top-app-bar-fixed": TauiTopAppBarFixed;
  }
}
