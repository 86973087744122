import { LitElement } from "lit";
import { property } from "lit/decorators";
import { Auth } from "../data/auth";
import { Connection } from "../data/connection";
import { TucanoAdminUI } from "../types";

export class TauiBaseEl extends LitElement {
  @property({ attribute: false }) public taui?: TucanoAdminUI;

  protected _pendingTaui: Partial<TucanoAdminUI> = {};

  // eslint-disable-next-line: variable-name
  private __provideTaui: HTMLElement[] = [];

  public provideTaui(el) {
    this.__provideTaui.push(el);
    el.taui = this.taui;
  }

  protected initializeTaui(_auth: Auth, _conn: Connection) {
    // implemented in connection-mixin
    // eslint-disable-next-line
  }

  // Exists so all methods can safely call super method
  protected tauiConnected() {
    // eslint-disable-next-line
  }

  protected tauiReconnected() {
    // eslint-disable-next-line
  }

  protected tauiDisconnected() {
    // eslint-disable-next-line
  }

  protected panelUrlChanged(_newPanelUrl) {
    // eslint-disable-next-line
  }

  protected tauiChanged(taui, _oldTaui) {
    this.__provideTaui.forEach((el) => {
      (el as any).taui = taui;
    });
  }

  protected _updateTaui(obj: Partial<TucanoAdminUI>) {
    if (!this.taui) {
      this._pendingTaui = { ...this._pendingTaui, ...obj };
      return;
    }
    this.taui = { ...this.taui, ...obj };
  }
}
