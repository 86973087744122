// @ts-ignore
// eslint-disable-next-line import/extensions
import { Resumable } from "resumablejs/resumable.js";
import { Profile, Tenant } from "@taui/shared/interfaces/openapi/adminui-api";
import { IdentityDTO } from "@taui/shared/interfaces/openapi/identity-api";
import { BackendService } from "@taui/shared/types/backend-service";
import { TauiScope } from "@taui/shared/types/taui-scope";
import { LocalizeFunc } from "./common/translations/localize";
import { Auth, AuthData } from "./data/auth";
import { Connection } from "./data/connection";
import { FrontendLocaleData } from "./data/translation";
import { TauiComponents, TauiConfig } from "./types/config";
import { Themes } from "./data/ws-themes";
import { ViewMode } from "./common/config/view_mode";

declare global {
  /* eslint-disable no-var, no-redeclare, @typescript-eslint/naming-convention */
  var __DEV__: boolean;
  var __DEMO__: boolean;
  var __BUILD__: "latest" | "es5";
  var __VERSION__: string;
  var __STATIC_PATH__: string;
  /* eslint-enable no-var, no-redeclare, @typescript-eslint/naming-convention */

  interface Window {
    ShadyCSS: {
      nativeCss: boolean;
      nativeShadow: boolean;
      prepareTemplate(templateElement, elementName, elementExtension);
      styleElement(element);
      styleSubtree(element, overrideProperties);
      styleDocument(overrideProperties);
      getComputedStyleValue(element, propertyName);
    };
  }

  // for fire event
  interface TAUIDomEvents {
    "value-changed": {
      dirty?: boolean;
      value: unknown;
    };
    change: undefined;
    "data-changed": {
      path?: string;
      field?: string;
      separator?: string;
      data?: any;
      dirty?: boolean;
      toDelete?: boolean;
    };
    "error-object-changed": {
      name: string;
      value: unknown;
    };
    "taui-logout": undefined;
    "iron-resize": undefined;
  }

  // For loading workers in webpack
  interface ImportMeta {
    url: string;
  }
}

export interface ValueChangedEvent<T> extends CustomEvent {
  detail: {
    value: T;
  };
}

export type Constructor<T = any> = new (...args: any[]) => T;

export interface ClassElement {
  kind: "field" | "method";
  key: PropertyKey;
  placement: "static" | "prototype" | "own";
  initializer?: (...args) => unknown;
  extras?: ClassElement[];
  finisher?: <T>(cls: Constructor<T>) => undefined | Constructor<T>;
  descriptor?: PropertyDescriptor;
}

export interface Translation {
  nativeName: string;
  isRTL: boolean;
  hash: string;
}

export interface TranslationMetadata {
  fragments: string[];
  translations: {
    [lang: string]: Translation;
  };
}

export interface IconMetaFile {
  version: string;
  parts: IconMeta[];
}

export interface IconMeta {
  start: string;
  file: string;
}

export interface Resources {
  [language: string]: Record<string, string>;
}

export interface BackendLanguages {
  iso: string;
  language: string;
}

// Currently selected theme and its settings. These are the values stored in local storage.
// Note: These values are not meant to be used at runtime to check whether dark mode is active
// or which theme name to use, as this interface represents the config data for the theme picker.
// The actually active dark mode and theme name can be read from taui.themes.
export interface ThemeSettings {
  theme: string;
  // Radio box selection for theme picker. Do not use in Dashboard rendering as
  // it can be undefined == auto.
  // Property taui.themes.darkMode carries effective current mode.
  dark?: boolean;
  primaryColor?: string;
  accentColor?: string;
}

export enum TauiService {
  // AdminUI services
  "grpc" = "grpc",
  "config" = "config",
}

export type TauiServices = BackendService | TauiService;

export interface TabInfo
  extends Omit<PanelInfo, "children" | "component_name"> {
  base_url_path: string;
  component_name?: string;
  name?: string;
  edited?: boolean;
  error?: boolean;
}

export interface PanelInfo {
  component_name: string;
  icon?: string;
  children?: PanelInfo[];
  services?: TauiServices[];
  config?: string;
  url_path: string;
  search?: string;
  scopes?: TauiScope[];
  isMaster?: boolean;
}

export interface Panels {
  [name: string]: PanelInfo;
}

export interface UploadAsset {
  assetId: string;
  mediaId: string;
  r: Resumable;
  md5sum?: string;
}

export interface DynamicColumn {
  id: string;
  dynamicColumn: { index: number; checked: boolean }[];
}

export interface TucanoAdminUI {
  auth: Auth;
  connection: Connection;
  connected: boolean;
  config: TauiConfig;
  themes: Themes;
  selectedTheme?: ThemeSettings | null;
  panels: Panels;
  components?: TauiComponents;
  structure: any;
  viewMode: ViewMode;
  panelUrl: string;
  services: PanelInfo[];
  languages: string[];
  defaultLanguage: string;
  language: string;
  // local stored language, keep that name for backward compability
  selectedLanguage: string | null;
  defaultDashboard: number;
  previousIdentity: AuthData | null;
  selectedPanel: string;
  tabs: TabInfo[];
  dynamicColumn: DynamicColumn[];
  idsUpload: string[];
  locale: FrontendLocaleData;
  resources: Resources;
  localize: LocalizeFunc;
  translationMetadata: TranslationMetadata;
  suspendWhenHidden: boolean;
  enableShortcuts: boolean;
  vibrate: boolean;
  dockedSidebar: "docked" | "always_hidden" | "auto";
  user: IdentityDTO;
  tenantMaster: boolean;
  allTenants: Tenant[];
  selectedTenant: string;
  tenants: string[];
  profile?: Profile;
  currentScopes?: any;
  hasAnyScopes(scopes?: TauiScope[]): boolean;
  hasService?: any;
  upload: {
    current: UploadAsset | undefined;
    next: string[];
  };
  loading: boolean;
  tauiUrl(path?, fake?: boolean): string;
  callApi<T>(
    method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH",
    service: TauiServices,
    path: string,
    parameters?: Record<string, any>,
    headers?: Record<string, string>
  ): Promise<T>;
  fetchWithAuth(path: string, init?: Record<string, any>): Promise<Response>;
  loadFragmentTranslation(fragment: string): Promise<LocalizeFunc | undefined>;
}

export interface Route {
  prefix: string;
  path: string;
  search?: string;
}

export interface BreadcrumbPath {
  key: string;
  label?: string;
  clickable: boolean;
}
