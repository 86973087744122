import {
  CreateProfileRequest,
  ListProfilesResponse,
  Profile,
} from "@taui/shared/interfaces/openapi/adminui-api";
import { TauiService, TucanoAdminUI } from "../../../../types";

export enum ProfilePermission {
  VISIBLE = "VISIBLE",
  HIDDEN = "HIDDEN",
}

export const grpcListProfiles = async (
  taui: TucanoAdminUI,
  params?: {
    pageNumber?: number;
    pageSize?: number;
    id?: number;
    fts?: string;
  }
) =>
  taui.callApi<ListProfilesResponse>(
    "GET",
    TauiService.grpc,
    "/identity/profile/list",
    params
  );

export const grpcGetProfile = async (taui: TucanoAdminUI, id: number) =>
  taui.callApi<Profile>("GET", TauiService.grpc, `/identity/profile/${id}`);

export const grpcCreateProfile = async (
  taui: TucanoAdminUI,
  params: CreateProfileRequest
) => taui.callApi<void>("POST", TauiService.grpc, "/identity/profile", params);

export const grpcUpdateProfile = async (
  taui: TucanoAdminUI,
  id: number,
  params: Profile
) =>
  taui.callApi<void>(
    "PUT",
    TauiService.grpc,
    `/identity/profile/${id}`,
    params
  );

export const grpcDeleteProfile = async (taui: TucanoAdminUI, id: number) =>
  taui.callApi<void>("DELETE", TauiService.grpc, `/identity/profile/${id}`);
